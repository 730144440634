import Image from "next/image";
import styles from "./styles.module.scss";
import useTranslation from "next-translate/useTranslation";

const SingleReview = () => {
  const {t} = useTranslation("home");
  const review = {
    "header": t("singleReview.header"),
    "text": t("singleReview.text"),
    "name": "lala Berlin",
    "imgSrc": "/common/lala-logo.png"
  }

  return (
    <section className={styles["review"]}>
      <div className={styles["review__container"]}>
        <div className={styles["review__info"]}>
          <div className={styles["review__header-wrapper"]}>
            <h4 className={styles["review__header"]}>{review.header}</h4>
          </div>
          <p className={styles["review__text"]}>{review.text}</p>
          <footer className={styles["review__footer"]}>
            <span className={styles["review__name"]}>{review.name}</span>
          </footer>
        </div>
        <div className={styles["review__img-wrapper"]}>
          <Image src={review.imgSrc} width={421} height={241} alt="Lala Berlin testimonial" layout={"responsive"}/>
        </div>
      </div>
    </section>
  );
};

export default SingleReview;